/* Webfont: Lato-Hairline */@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local("LatoHairline"), local("Lato-Hairline"),
       url('./typography/lato/Lato-Hairline.woff2') format('woff2'), /* Modern Browsers */
       url('./typography/lato/Lato-Hairline.woff') format('woff'), /* Modern Browsers */
       url('./typography/lato/Lato-Hairline.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 200;
  src: local("LatoThin"), local("Lato-Thin"),
       url('./typography/lato/Lato-Thin.woff2') format('woff2'), /* Modern Browsers */
       url('./typography/lato/Lato-Thin.woff') format('woff'), /* Modern Browsers */
       url('./typography/lato/Lato-Thin.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local("LatoLight"), local("Lato-Light"),
       url('./typography/lato/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
       url('./typography/lato/Lato-Light.woff') format('woff'), /* Modern Browsers */
       url('./typography/lato/Lato-Light.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local("LatoWeb"), local("Lato"),
       url('./typography/lato/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('./typography/lato/Lato-Regular.woff') format('woff'), /* Modern Browsers */
       url('./typography/lato/Lato-Regular.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local("LatoWebItalic"), local("Lato-Italic"),
       url('./typography/lato/Lato-Italic.woff2') format('woff2'), /* Modern Browsers */
       url('./typography/lato/Lato-Italic.woff') format('woff'), /* Modern Browsers */
       url('./typography/lato/Lato-Italic.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Semibold */@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  src: local("LatoSemibold"), local("Lato-Semibold"),
       url('./typography/lato/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */
       url('./typography/lato/Lato-Semibold.woff') format('woff'), /* Modern Browsers */
       url('./typography/lato/Lato-Semibold.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  src: local("LatoWebBold"), local("Lato-Bold"),
       url('./typography/lato/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
       url('./typography/lato/Lato-Bold.woff') format('woff'), /* Modern Browsers */
       url('./typography/lato/Lato-Bold.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Heavy */@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local("LatoWebHeavy"), local("Lato-Heavy"),
       url('./typography/lato/Lato-Heavy.woff2') format('woff2'), /* Modern Browsers */
       url('./typography/lato/Lato-Heavy.woff') format('woff'), /* Modern Browsers */
       url('./typography/lato/Lato-Heavy.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Black */@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  src: local("LatoWebBlack"), local("Lato-Black"), 
       url('./typography/lato/Lato-Black.woff2') format('woff2'), /* Modern Browsers */
       url('./typography/lato/Lato-Black.woff') format('woff'), /* Modern Browsers */
       url('./typography/lato/Lato-Black.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}
