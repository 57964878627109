@import "../../../../styles/variables.css";

.error {
  color: var(--red);
}

.forgot {
  margin-top: 15px;
}

.submitButton {
  max-width: 212px;
  margin: 52px auto 23px;
}

@media (min-width: 768px) {
  .forgot {
    margin-top: 3px;
    text-align: right;
  }
}
