@import '../../styles/variables.css';

.root {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  text-align: center;
  transform: translate(-50%, -50%);
}
.image {
  max-height: 120px;
  margin-bottom: 0.67em;
  font-size: 2em;
}
.root p {
  margin-bottom: 0;
}

.action {
  margin-top: 1.34em;
}
