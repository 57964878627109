@import "../../../styles/variables.css";

.root {
  position: relative;
  min-height: 130px;
}

.list {
  margin-bottom: 10px !important;
}

.empty {
  min-height: 300px;
}
