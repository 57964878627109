.container {
  display: flex;
  justify-content: space-between;
}

.textCol {
  display: flex;
  flex-direction: column;
  flex: 5;
  margin-right: 120px;
  max-width: 406px;
  justify-content: center;
}

.imageCol {
  display: flex;
  align-items: center;
  flex: 6;
}

a.connectButton,
button.connectButton {
  min-width: 220px;
  padding: 13px 25px;
  width: fit-content;
  font-weight: 500;
  font-size: 20px !important;
}

a.contactUsButton,
button.contactUsButton {
  padding: 0;
  width: fit-content;
  font-weight: 400;
  font-size: 20px !important;
  color: #1a76d2 !important;
}

a.contactUsButtonSmall,
button.contactUsButtonSmall {
  padding: 0;
  width: fit-content;
  font-weight: 400;
  font-size: 16px !important;
  color: #1a76d2 !important;
}

@media (max-width: 1200px) {
  .textCol {
    margin-right: 80px;
  }
}

@media (max-width: 900px) {
  .textCol {
    margin-right: 50px;
  }
}

@media (max-width: 768px) {
  .textCol {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }

  .container {
    text-align: center;
    padding-right: 60px;
  }

  .imageCol {
    display: none;
  }
}
