@import "../../styles/variables.css";

.root {
  position: relative;
}
.load {
  min-height: 200px;
}
.actions {
  position: absolute;
  top: 30px;
  right: 35px;
}
.icon {
  font-size: 22px;
  color: var(--gray);
  display: inline-block;

  &:first-child {
    margin-bottom: 10px;
  }
  &:hover {
    color: var(--dark-gray)
  }
}
.pages {
  text-align: center;
}
.page {
  box-shadow: -1px -1px 14px 6px rgba(78,92,110,0.12);
  display: inline-block;
  margin: 10px auto;
}
