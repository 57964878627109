@import '../../../styles/variables.css';

.root {
  padding: 0 15px 82px 15px;
}
.root p:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .root {
    padding: 0 20px 20px 20px;
  }
}
