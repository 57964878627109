@import "../../../styles/variables.css";

.Select-control {
  border-radius: var(--border-radius-md) !important;
  border: 1px solid var(--border-color) !important;
  height: 42px !important;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 41px !important;
}

.Select-input {
  height: 40px !important;
}
.Select-input > input {
  line-height: 27px !important;
}

.Select-menu-outer {
  border-color: var(--border-color) !important;
}

.Select-option {
  line-height: 30px !important;
}

.Select-option.is-focused {
  background-color: color(var(--motive) lightness(+50%)) !important;
}

.is-focused:not(.is-open) > .Select-control {
  border-color: var(--gray) !important;
  box-shadow: none !important;
}

.hasError .Select-control {
  border-color: var(--red) !important;
}
.Select-arrow-zone {
  background-color: #ffffff !important;
}
.Select-clear-zone {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+25 */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
