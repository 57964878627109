@import "../../styles/variables.css";

.search {
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  padding: 8px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid var(--border-color);
}

.menu {
  margin-left: -20px;
  margin-right: -20px;
  background-color: #ffffff;
}
.menu li:not(:first-child) {
  border-top: 1px solid var(--border-color);
}
.menu li > a {
  display: block;
  padding: 16px 20px;
}

@media (min-width: 768px) {
  .search {
    margin-top: 0;
  }
}
