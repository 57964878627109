@import '../../styles/variables.css';

.root {
  z-index: 999;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background-color: #111;
  color: #fff;
  border-radius: var(--border-radius-xs);
  padding: 5px 0;
  text-align: left;
  margin-top: 0;
  visibility: hidden;
  opacity: 0;
  font-size: 13px;
  box-shadow: 0 3px 8px rgba(0,0,0,.25), 0 0 1px rgba(0,0,0,0.37);
  transition: all 0.3s ease-in-out;
}
.root.top {
  top: auto;
  bottom: 90%;
  margin-top: 0;
  margin-bottom: 0;
}
.active {
  visibility: visible;
  opacity: 1;
  margin-top: 5px;
}
.active.top {
  margin-top: 0;
  margin-bottom: 5px;
}

.right {
  left: auto;
  right: -5px;
}

.root ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.root li {
  display: block;
  list-style: none;
}

.root :global li.separator {
  border-top: 1px solid #333;
  border-bottom: none;
  margin: 5px 0;
}

.root li > a,
.root  li > span,
.root  li > button {
  transition: 0.2s all ease;
  background: transparent;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  display: block;
  color: inherit;
  text-align: left;
  text-decoration: none;
}

.root li > a:hover,
.root li > a:focus,
.root li > a:active,
.root  li > button:hover,
.root  li > button:focus,
.root  li > button:active {
  background-color: #222;
  text-decoration: none;
}
