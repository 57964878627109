@import "../../styles/variables.css";

.root {
  padding: 10px 15px 5px 15px;
}

.loginForm {
  margin: 0 auto 29px;
  width: 60%;
  min-width: 350px;
}

@media (min-width: 768px) {
  .root {
    padding: 10px 20px 5px 20px;
  }
}
