@import '../../../styles/variables.css';

.root {
  background-color: var(--gray-light);
  border-top: 1px solid var(--border-color);
  border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

:global .aside :local .root {
  background-color: #ffffff;
}  

@media (min-width: 768px) {
  .root {
    position: relative;
    padding: 15px 20px;
  }

  :global .aside :local .root {
    position: absolute;
  }  
}
