@import "./variables.css";

.Select-menu-outer {
  border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
}

::-ms-reveal {
  display: none;
}

label {
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 5px;
  display: block;
}

input,
select,
textarea,
.fauxInput {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: 12px 15px;
  transition: border 0.2s ease;
  &:focus {
    border-color: var(--gray);
  }
}
.fauxInput {
  cursor: pointer;
}
textarea {
  resize: vertical;
}

input,
textarea,
[contenteditable="true"] {
  width: 100%;
  display: block;
  min-width: 65px;
  &:focus {
    outline: none;
  }
}

.inputGroup {
  display: table;
  width: 100%;
}
.inputAddon {
  vertical-align: middle;
  display: table-cell;
  padding: 3px 8px;
  min-width: 35px;
  text-align: center;
  border: 1px solid var(--border-color);
  border-right: none;
  border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
  background-color: var(--gray-light);
}
.inputAddonOnRight {
  vertical-align: middle;
  display: table-cell;
  padding: 3px 8px;
  min-width: 35px;
  text-align: center;
  border: 1px solid var(--border-color);
  border-left: none;
  border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
  background-color: var(--gray-light);
  cursor: pointer;
}
.inputAddonBtn {
  display: table-cell;
  width: 1%;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
.inputGroup input:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.inputAddon + input,
.inputAddonBtn + input {
  display: table-cell;
  vertical-align: middle;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.AddonOnRight + input {
  display: table-cell;
  vertical-align: middle;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.inputAddonBtn:first-child button {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.inputAddonBtn:last-child button {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.checkbox,
.radio {
  position: relative;
  vertical-align: middle;
  user-select: none;
}
.checkbox:after,
.radio:after {
  content: "";
  clear: both;
  display: block;
}
.checkbox label,
.radio label {
  cursor: pointer;
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 10px;
}
.checkbox input[type="checkbox"]:focus + label:before,
.radio input[type="radio"]:focus + label:before {
  border: 2px solid var(--gray);
  width: 18px;
  height: 18px;
}
.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.checkbox input[type="checkbox"] + label:before,
.radio input[type="radio"] + label:before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  line-height: 20px;
  font-size: 20px;
  margin-right: 5px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid var(--border-color);
}
.checkbox input[type="checkbox"] + label:before {
  border-radius: var(--border-radius-xs);
  background: #ffffff;
}
.checkbox input[type="checkbox"]:disabled + label:before {
  border-radius: var(--border-radius-xs);
  background: var(--gray-light);
}

.checkbox input[type="checkbox"]:disabled + label {
  cursor: default;
}

.radio input[type="radio"] + label:before {
  border-radius: 50%;
}

.checkbox input[type="checkbox"]:checked + label:after,
.radio input[type="radio"]:checked + label:after {
  opacity: 1;
}
.checkbox input[type="checkbox"]:checked + label:before,
.radio input[type="radio"]:checked + label:before {
  border-color: var(--motive);
}

.checkbox label:after,
.radio label:after {
  position: absolute;
  content: "";
  opacity: 0;
  transition: all 0.3s ease;
}

.checkbox label:after {
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border-radius: var(--border-radius-xs);
}

.radio label:after {
  left: 3px;
  top: 3px;
  width: 16px;
  height: 16px;
}
.checkbox label:after {
  background: var(--motive)
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIGZpbGw9IiNmZmZmZmYiID4gICAgPHBhdGggc3R5bGU9InRleHQtaW5kZW50OjA7dGV4dC1hbGlnbjpzdGFydDtsaW5lLWhlaWdodDpub3JtYWw7dGV4dC10cmFuc2Zvcm06bm9uZTtibG9jay1wcm9ncmVzc2lvbjp0YjstaW5rc2NhcGUtZm9udC1zcGVjaWZpY2F0aW9uOkJpdHN0cmVhbSBWZXJhIFNhbnMiIGQ9Ik0gMjguMjgxMjUgNi4yODEyNSBMIDExIDIzLjU2MjUgTCAzLjcxODc1IDE2LjI4MTI1IEwgMi4yODEyNSAxNy43MTg3NSBMIDEwLjI4MTI1IDI1LjcxODc1IEwgMTEgMjYuNDA2MjUgTCAxMS43MTg3NSAyNS43MTg3NSBMIDI5LjcxODc1IDcuNzE4NzUgTCAyOC4yODEyNSA2LjI4MTI1IHoiIGNvbG9yPSIjMDAwIiBvdmVyZmxvdz0idmlzaWJsZSIgZm9udC1mYW1pbHk9IkJpdHN0cmVhbSBWZXJhIFNhbnMiPjwvcGF0aD48L3N2Zz4=")
    50% 50% no-repeat;
}
.checkbox.some label:after {
  background: var(--motive)
    url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiBmaWxsPSIjZmZmZmZmIiB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAwIDEwMDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+PHBhdGggZD0iTTQ5LDUzNC4yaDM2NC40SDQ2MUg1MzloMjguNkg5NTFjMjEuNiwwLDM5LTE1LjMsMzktMzQuMmMwLTE4LjktMTcuNS0zNC4yLTM5LTM0LjJsLTM4My4zLDBINTM5SDQ2MWgtNDcuNUg0OWMtMjEuNiwwLTM5LDE1LjMtMzksMzQuMkMxMCw1MTguOSwyNy41LDUzNC4yLDQ5LDUzNC4yeiIvPjwvZz4NCjwvc3ZnPg==")
    50% 50% no-repeat;
  background-size: 50%;
}
.radio label:after {
  background: var(--motive);
  border-radius: 50%;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  vertical-align: middle;
}

.switch.lite {
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.slider.lite:before {
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
}

.switch input:checked + .slider {
  background-color: var(--motive);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--motive);
}

.switch input:checked + .slider:before {
  transform: translateX(30px);
}

.switch.lite input:checked + .slider:before {
  transform: translateX(17px);
}

.switch input:disabled + .slider {
  background: var(--gray);
  cursor: not-allowed;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--placeholder);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--placeholder);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--placeholder);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: var(--placeholder);
}

.inputContainer {
  margin-bottom: 15px;
}
.hasError input,
.hasError select,
.hasError textarea,
.hasError .inputAddon {
  border-color: var(--red);
}
.hasError label {
  color: var(--red);
}
.inputError {
  color: var(--red);
  font-size: 13px;
  margin-top: 5px;
  display: block;
  word-break: break-all;
}

.react-datepicker__close-icon {
  position: absolute;
  right: -14px;
  top: 50%;
}
.inputAddon + div .react-datepicker-wrapper input {
  padding-left: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.doubleLabelHeight > label {
  height: 30px;
}
