@import "../../styles/variables.css";

.root {
  border-radius: var(--border-radius-xs);
}

.root iframe {
  overflow-y:hidden;
}

.root iframe html {
  overflow-y:hidden;
}
