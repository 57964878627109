@import "./variables.css";

/**
* Colors
*/

.c-motive {
  color: var(--motive);
}
.c-motive--dark {
  color: var(--motive-dark);
}
.c-gray {
  color: var(--gray);
}
.c-gray-light {
  color: var(--gray-light);
}
.c-black {
  color: var(--black);
}
.c-white {
  color: #ffffff;
}
.c-red {
  color: var(--red);
}
.c-orange {
  color: var(--orange);
}
.c-green {
  color: var(--green);
}
.c-inherit {
  color: inherit !important;
}

/**
* Opacity
*/

.op-7 {
  opacity: 0.7;
}

/**
* Background Colors
*/

.bg-body {
  background-color: #f3f6f8;
}
.bg-white {
  background-color: #ffffff;
}
.bg-motive {
  background-color: var(--motive);
}
.bg-motive--dark {
  background-color: var(--motive-dark);
}
.bg-gray {
  background-color: var(--gray);
}
.bg-gray-light {
  background-color: var(--gray-light);
}
.bg-red {
  background-color: var(--red);
}
.bg-green {
  background-color: var(--green);
}
.bg-orange {
  background-color: var(--orange);
}

/**
* Borders
*/

.border-top {
  border-top: 1px solid var(--border-color);
}
.border-dark {
  border-color: var(--border-color-dark) !important;
}
.border-bottom {
  border-bottom: 1px solid var(--border-color);
}
.no-border {
  border: none !important;
}

/**
* Floats
*/

.left {
  float: left;
}

.right {
  float: right;
}

/**
* Displays
*/

.block {
  display: block !important;
}

.inlineBlock {
  display: inline-block !important;
}

/**
* Font Weights
*/

.w200 {
  font-weight: 200;
}

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}
.w700 {
  font-weight: 700;
}

/**
* Text Alignment
*/

.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.textLeft {
  text-align: left;
}
/**
* Text Transform
*/

.textUppercase {
  text-transform: uppercase;
}
.textCapitalize {
  text-transform: capitalize;
}
.noTransform {
  text-transform: none;
}

/**
* Text Misc
*/

.textTruncate {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakWord {
  word-break: break-word;
}
.breakAll {
  word-break: break-all;
}
/**
  * Line Height
  */

.lh-10 {
  line-height: 1;
}
.lh-13 {
  line-height: 1.3;
}
.lh-16 {
  line-height: 1.6;
}
.lh-18 {
  line-height: 1.8;
}
.lh-20 {
  line-height: 2;
}

/**
  * Font Size
  */

.fs-9 {
  font-size: 9px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fs-30 {
  font-size: 30px;
}
.fs-32 {
  font-size: 32px;
}
.fs-35 {
  font-size: 35px;
}
.fs-38 {
  font-size: 38px;
}
.fs-40 {
  font-size: 40px;
}
.fs-50 {
  font-size: 50px;
}
.fs-62 {
  font-size: 62px;
}

/**
  * Margins
  */

.no-m {
  margin: 0 !important;
}

.m-l-a {
  margin-left: auto;
}
.m-r-a {
  margin-right: auto;
}
.m-t-a {
  margin-top: auto;
}
.m-t-0 {
  margin-top: 0;
}
.m-b-0 {
  margin-bottom: 0;
}

.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-b-8 {
  margin-bottom: 8px;
}
.m-t-8 {
  margin-top: 8px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}

.m-l-12 {
  margin-left: 12px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}

.m-t-20 {
  margin-top: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-t-25 {
  margin-top: 20px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-l-40 {
  margin-left: 40px;
}

.m-t-30 {
  margin-top: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-r-30 {
  margin-right: 30px;
}

.m-v-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-r-40 {
  margin-right: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-v-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.m-v-54 {
  margin-top: 54px;
  margin-bottom: 54px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-t-60 {
  margin-top: 60px;
}
.m-t-70 {
  margin-top: 70px;
}
.m-b-60 {
  margin-bottom: 60px;
}
.m-t-80 {
  margin-top: 80px;
}
.m-b-80 {
  margin-bottom: 80px;
}
.m-t-100 {
  margin-top: 100px;
}

/**
  * Paddings
  */

.no-p {
  padding: 0;
}
.no-p-b {
  padding-bottom: 0 !important;
}
.no-p-t {
  padding-top: 0 !important;
}
.no-p-r {
  padding-right: 0;
}
.p-t-5 {
  padding-top: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-r-5 {
  padding-right: 5px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-10 {
  padding: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.p-30 {
  padding: 30px;
}
.p-v-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-50 {
  padding: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-t-60 {
  padding-top: 60px;
}
.p-l-60 {
  padding-left: 60px;
}
.p-v-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.p-v-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.p-h-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-b-60 {
  padding-bottom: 60px;
}

.p-t-80 {
  padding-top: 80px;
}

/**
  * Positions
  */

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

/**
  * Lists
  */

.l-unstyled,
.l-checks {
  list-style: none;
  padding: 0;
  margin: 0;
}
.l-inline > li {
  display: block;
  float: left;
}

.l-checks li:before {
  content: "🗸";
  margin-right: 10px;
}

.l-bordered > li,
.l-striped > li {
  border-top: 1px solid var(--border-color);
}

.l-striped > li:nth-child(odd) {
  background-color: var(--gray-light);
}

/**
  * Alignment
  */

.v-m {
  vertical-align: middle;
}

.v-t {
  vertical-align: top;
}
.v-b {
  vertical-align: bottom;
}
/**
  * Cursors
  */

.cr-pointer {
  cursor: pointer;
}

/**
  * Clearfix
  */

.clearfix:after,
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/**
  * Flexbox
  */

.flex-container {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.f-j-end {
  justify-content: flex-end;
}

.f-j-start {
  justify-content: flex-start;
}

.f-j-center {
  justify-content: center;
}

.f-j-between {
  justify-content: space-between;
}

.f-j-around {
  justify-content: space-around;
}
.f-j-evenly {
  justify-content: space-evenly;
}

.f-a-start {
  align-items: flex-start;
}

.f-a-end {
  align-items: flex-end;
}

.f-a-center {
  align-items: center;
}

.f-a-baseline {
  align-items: baseline;
}

.f-a-stretch {
  align-items: stretch;
}

.f-d-row {
  flex-direction: row;
}

.f-d-row-reverse {
  flex-direction: row-reverse;
}

.f-d-col {
  flex-direction: column;
}

.f-d-col-reverse {
  flex-direction: column-reverse;
}

.f-b-300 {
  flex-basis: 300px;
}

.f-b-350 {
  flex-basis: 350px;
}

.f-s-auto {
  align-self: auto;
}

.f-s-start {
  align-self: flex-start;
}

.f-s-end {
  align-self: flex-end;
}

.f-s-center {
  align-self: center;
}

.f-s-baseline {
  align-self: baseline;
}

.f-s-stretch {
  align-self: stretch;
}

.no-shrink {
  flex-shrink: 0;
}

.shrink-2 {
  flex-shrink: 0.2;
}

.grow-1 {
  flex-grow: 1;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

/* Gap */
.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-row-5 {
  grid-row-gap: 5px;
}

.gap-row-10 {
  grid-row-gap: 10px;
}

.gap-col-15 {
  grid-column-gap: 15px;
}

.gap-col-20 {
  grid-column-gap: 20px;
}

.gap-col-25 {
  grid-column-gap: 25px;
}

/**
  * Widths
  */

.wd100 {
  width: 100%;
}

.wd220 {
  width: 220px;
}

.m-wd415 {
  max-width: 415px;
}
/**
  * Heights
  */
.fullheight {
  height: 100%;
}
/**
  * Misc
  */
.no-pointer-events {
  pointer-events: none;
}

.no-select {
  user-select: none;
}

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}

/*SVG color filters*/
.svg-grey-color {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(110%)
    contrast(88%);
}
