@import "../../../../../styles/variables.css";

.root {
  position: relative;
  display: inline-block;
  padding: 8px 16px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  border-radius: var(--border-radius-xs);
  background-color: var(--gray-light);
  transition: 0.2s all ease-in-out;
  vertical-align: middle;
  word-break: break-all;
}

.delete {
  margin-left: 10px;
}

@media (min-width: 1024px) {
  .delete {
    z-index: 1;
    position: absolute;
    padding: 8px 5px;
    top: -1px;
    right: -16px;
    opacity: 0;
    border: 1px solid transparent;
    border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
    background-color: var(--gray-light);
  }
  .root:hover {
    border-color: var(--motive-dark);
  }
  .root:hover .delete {
    border-top-color: var(--motive-dark);
    border-right-color: var(--motive-dark);
    border-bottom-color: var(--motive-dark);
  }
  .root:hover .delete {
    opacity: 1;
  }
}
