@import "../../styles/variables.css";

.root {
  position: relative;
  z-index: 2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  min-height: 64px;
}
.toggle {
  float: left;
  width: 50px;
  height: 50px;
  margin: 7px 5px 7px 0;
}
.logo {
  width: 156px;
  max-width: 156px;
  float: left;
  padding: 8px 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.zendeskLink {
  color: #1976d2;
  text-decoration: none;
}
.menu {
  z-index: 1000;
  background-color: #ffffff;
  float: left;
  font-size: 14px;
  position: fixed;
  top: 0;
  left: -260px;
  bottom: 0;
  width: 260px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-in-out;
  overflow-y: auto;
}
.menu.open {
  left: 0;
}
.menu > ul > li > a,
.link {
  padding: 15px 15px;
  text-align: left;
}
.text {
  padding: 15px 15px;
}
.menu a,
.link,
.text {
  display: block;
  color: #666;
}
.menu a:hover,
.menu a:focus,
.menu a:active,
.link:hover,
.link:focus,
.link:active {
  color: inherit;
  text-decoration: none;
}
.menu a.active {
  color: var(--motive);
}
.dropdown a.active,
.dropdown a.active:hover,
.dropdown a.active:focus,
.dropdown a.active:active {
  background-color: var(--motive);
}
.dropdownContent {
  z-index: 1000;
}
.menu button {
  width: 100%;
}
.withLabel {
  position: relative;
  margin-right: 20px;
}
.menuLabel {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 9px;
  padding: 1px 4px;
}
.menuDropdownRoot {
  display: block;
}
.menuDropdown {
  z-index: 999;
  background: var(--motive-dark);
  visibility: hidden;
  opacity: 0;
  transition: top opacity 0.3s ease-in-out;
  position: relative;
  height: 0;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

.menuDropdown a.active {
  color: #fff;
}
.menuDropdownActive {
  visibility: visible;
  opacity: 1;
  height: auto;
  padding: 5px 0;
}
.menuDropdownHeader {
  display: block;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 15px 5px 15px;
  color: var(--gray-light);
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.backdrop.open {
  opacity: 1;
  visibility: visible;
}

.root span[class^="icon-arrow"] {
  float: right;
  margin-left: 5px;
}

.user {
  padding: 5px 0;
}
.name {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1;
}

.help {
  margin: 16px 15px;
}

@media (min-width: 992px) {
  .logo {
    margin-right: 15px;
    position: relative;
    left: 0;
    transform: translateX(0);
  }
  .menu {
    float: left;
    position: initial;
    width: auto;
    box-shadow: none;
    overflow: unset;
  }
  .menu button {
    width: auto;
  }
  .menu li {
    display: inline-block;
  }
  .menu > ul > li > a,
  .link {
    padding: 24px 0;
    margin: 0 15px;
  }
  .text {
    padding: 25px 0;
    margin: 0 15px;
  }
  .userLink {
    padding: 18px 0;
  }
  .menu > ul > li > .active {
    padding-bottom: 21px;
  }
  .menuDropdown a,
  .menuDropdown [type="button"] {
    margin: 0 15px;
    padding: 10px 0;
  }
  .menuDropdown a.active,
  .menuDropdown [type="button"].active {
    border-bottom: 3px solid #fff;
    padding-bottom: 2px !important;
  }
  .menuDropdown {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 50px;
    padding: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #fafafa;
  }
  .menuDropdown a,
  .menuDropdown [type="button"] {
    font-size: 14px !important;
    width: auto !important;
    margin: 0 15px !important;
    padding: 5px 0 !important;
    background: transparent !important;
  }
  .menuDropdown a:hover,
  .menuDropdown a:focus,
  .menuDropdown a:active,
  .menuDropdown [type="button"]:hover,
  .menuDropdown [type="button"]:focus,
  .menuDropdown [type="button"]:active {
    color: inherit !important;
  }
  .menuDropdownActive {
    top: 64px;
  }
  .menuDropdownCol {
    display: inline-block;
  }
  .root span[class^="icon-"] {
    float: none;
  }
  .backdrop,
  .backdrop.open {
    display: none;
  }

  .user {
    padding: 0;
    display: block;
  }
}

.beta {
  margin-right: 15px;
  color: inherit;
}

.beta :global .switch {
  width: 40px;
  height: 18px;
  margin-left: 5px;
}
.beta :global .switch label {
  border-radius: 14px;
}
.beta :global .switch input:checked + .slider:before {
  transform: translateX(21px);
}
.beta :global .slider:before {
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
}
