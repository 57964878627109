@import "../../../styles/variables.css";

.root {
  position: relative;
  border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
}

.header {
  padding: 10px 15px 10px 15px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--gray-light);
  margin-bottom: 20px;
  min-height: 57px;
  line-height: 35px;
}

:global .slideup :local .header.empty {
  line-height: 0;
  min-height: 0;
}

:global .slideup :local .header.empty .close {
  position: absolute;
  color: #ffffff;
  bottom: 40px;
  font-size: 40px;
  right: calc(50% - 20px);
}

:global .slideup :local .header.empty .close :global .icon-close {
  display: block !important;
}

:global .slideup :local .header.empty .close :global .icon-arrow-left {
  display: none !important;
}

:global .aside :local .header {
  background-color: #ffffff;
  border-bottom: 1px solid var(--border-color);
}

.header h1 {
  font-size: 18px;
  margin-bottom: 5px;
}
.header h1:last-child {
  margin: 0;
}

.error {
  background-color: var(--red);
  color: #ffffff;
}

.content {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  max-width: 85%;
}

.close {
  cursor: pointer;
  outline: none;
  text-align: center;
  font-size: 20px;
  border: none;
  padding: 0;
  margin-right: 10px;
  vertical-align: middle;
  line-height: 1;
}

@media (min-width: 768px) {
  .close,
  :global .slideup :local .header.empty .close {
    z-index: 101;
    position: absolute;
    top: -20px;
    right: -20px;
    margin-right: 0;
    left: auto;
    color: #ffffff;
  }

  :global .slideup :local .header.empty .close {
    bottom: auto;
    font-size: initial;
  }

  :global .aside :local .close {
    position: relative;
    top: 0;
    right: 0;
    margin-right: 10px;
    color: inherit;
  }
  :global .aside :local .header :global .icon-arrow-left {
    display: inline-block !important;
  }
  :global .aside :local .header :global .icon-close {
    display: none !important;
  }

  .header {
    padding: 20px;
    border: none;
    background: none;
    margin-right: 0;
    margin-bottom: 0;
    height: auto;
  }
  .header.empty {
    min-height: 0;
    padding: 10px;
  }
}
