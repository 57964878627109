@import "../../styles/animations.css";
@import "../../styles/variables.css";

.root {
  content: "";
  display: block;
  padding: 20px 0;
}

.floating {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  margin: auto;
  display: block;
  border-radius: 50%;
  width: 150px;
  padding: 0;
  border-top: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  border-left: 1px solid #ffffff;
  background-color: transparent;
  animation: load 1.1s infinite ease-out;
  overflow: hidden;
}
.loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.local {
  width: 80px;
}

.message {
  text-align: center;
}

@media (min-width: 992px) {
  .local {
    max-width: 90%;
  }
}
