@import "../../../../styles/variables.css";

.root {
  display: block;
  margin: auto;
  max-width: 212px;
  text-align: center;
  background-color: var(--ebay);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
  color: #ffffff;
  border-radius: var(--border-radius-xs);
  padding: 15px 30px;
  font-size: 20px;
  font-weight: 500;
}
.root:hover,
.root:focus,
.root:active {
  color: #ffffff;
  text-decoration: none;
  background-color: color(var(--ebay) blackness(10%));
}
.root img {
  width: 47px;
  margin-top: -2px;
  margin-left: 3px;
  vertical-align: middle;
}
