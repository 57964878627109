@import "../../styles/variables.css";

.logo {
  max-width: 190px;
  margin: auto;
}

.motto {
  color: var(--black);
  text-align: center;
  font-size: 16px;
}
.underline:after {
  content: "";
  display: block;
  background-color: var(--border-color);
  height: 4px;
  margin: 10px auto 20px;
  width: 35px;
}
