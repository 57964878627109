@import "../../styles/variables.css";

.root,
a.root {
  display: inline-block;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-xs);
  padding: 8px 10px;
  text-decoration: none;
  font-size: 13px;
  line-height: 1;
  text-align: center;
  transition: all 0.2s ease;
  cursor: pointer;
  letter-spacing: 0.04em;
  touch-action: manipulation;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}
.root + .root,
.root + a {
  margin-left: 10px;
}
.root:disabled {
  cursor: not-allowed;
}

.root[disabled]:not(.loading),
.root[disabled]:not(.loading):hover,
.root[disabled]:not(.loading):active,
.root[disabled]:not(.loading):focus {
  color: #ffffff;
}
.root[disabled]:not(.loading),
.root[disabled]:not(.loading):hover,
.root[disabled]:not(.loading):active,
.root[disabled]:not(.loading):focus {
  background-color: var(--gray);
  border-color: var(--gray);
}
.root[disabled].link:not(.loading),
.root[disabled].link:not(.loading):hover,
.root[disabled].link:not(.loading):active,
.root[disabled].link:not(.loading):focus,
.root[disabled].textLink:not(.loading),
.root[disabled].textLink:not(.loading):hover,
.root[disabled].textLink:not(.loading):active,
.root[disabled].textLink:not(.loading):focus {
  background-color: transparent;
  border-color: transparent;
  color: var(--gray);
}

/* COLORS */

.default,
a.default {
  background-color: #ffffff;
  color: var(--gray-dark);
  &:hover,
  &:focus,
  &:active {
    border-color: color(var(--border-color) blackness(+25%));
    color: var(--black);
  }
}

.grey,
a.grey {
  border-color: #f6f6f9;
  background-color: #f6f6f9;
  color: var(--gray-dark);
  &:hover,
  &:focus,
  &:active {
    border-color: color(var(--border-color) blackness(+25%));
    color: var(--black);
  }
}

.primary,
a.primary {
  border-color: var(--motive);
  background-color: var(--motive);
  color: #ffffff;
  &:hover,
  &:focus,
  &:active {
    border-color: #111;
    background-color: #111;
    color: #ffffff;
  }
}

.inverted,
a.inverted {
  border-color: #ffffff;
  background-color: #ffffff;
  color: var(--motive);
  &:hover,
  &:focus,
  &:active {
    border-color: var(--motive);
    background-color: var(--motive);
    color: #ffffff;
  }
}

.green,
a.green {
  border-color: var(--green);
  background-color: var(--green);
  color: #ffffff;
  &:hover,
  &:focus,
  &:active {
    border-color: #111;
    background-color: #111;
    color: #ffffff;
  }
}

.red,
a.red {
  border-color: var(--red);
  background-color: var(--red);
  color: #ffffff;
  &:hover,
  &:focus,
  &:active {
    border-color: #111;
    background-color: #111;
    color: #ffffff;
  }
}

.orange,
a.orange {
  border-color: var(--orange);
  background-color: var(--orange);
  color: #ffffff;
  &:hover,
  &:focus,
  &:active {
    border-color: #111;
    background-color: #111;
    color: #ffffff;
  }
}

.dark,
a.dark {
  border-color: #111;
  background-color: #111;
  color: #ffffff;
  &:hover,
  &:focus,
  &:active {
    border-color: var(--motive);
    background-color: var(--motive);
    color: #ffffff;
  }
}

.border,
a.border {
  border-color: #ffffff;
  color: #ffffff;
  &:hover,
  &:focus,
  &:active {
    border-color: var(--motive);
    background-color: var(--motive);
    color: #ffffff;
  }
}

.primary.border {
  background-color: #ffffff;
  border-color: var(--motive);
  color: var(--motive);
  &:hover,
  &:focus,
  &:active {
    border-color: color(var(--motive) blackness(+25%));
    color: #ffffff;
  }
}

.link,
a.link {
  border-color: transparent;
  background-color: transparent;
  color: var(--motive);
  &:hover,
  &:focus,
  &:active {
    color: inherit;
    border-color: transparent;
    background-color: transparent;
  }
}

.textLink,
a.textLink {
  padding: 0;
  letter-spacing: 0;
  font-size: inherit;
  color: var(--motive);
  border: none;
  background-color: transparent;
  &:hover,
  &:focus,
  &:active {
    color: inherit;
  }
}

.white,
a.white {
  background-color: #ffffff;
  color: var(--denim);
  font-weight: 400;
  font-size: 16px;
  &:hover,
  &:focus,
  &:active {
    border-color: color(var(--border-color) blackness(+25%));
    color: var(--black);
  }
}

/* SIZES */

.wide,
a.wide {
  padding: 8px 30px;
}

.small,
a.small {
  padding: 3px 8px;
}

.large,
a.large {
  padding: 13px 15px;
}

.landing-large,
a.landing-large {
  padding: 16px 30px;
  font-size: 20px;
  font-weight: 500;
}

.block,
a.block {
  display: block;
  width: 100%;
}

/* LOADING */

.loading {
  position: relative;
  padding-right: 38px;
}
.loading:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  display: block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding: 0;
  border-top: 1px solid var(--motive-dark);
  border-right: 1px solid var(--motive-dark);
  border-bottom: 1px solid var(--motive-dark);
  border-left: 1px solid #ffffff;
  background-color: transparent;
  animation: load 1.1s infinite linear;
  overflow: hidden;
  &:hover,
  &:focus,
  &:active {
    border-color: var(--motive-dark);
    border-left-color: #ffffff;
    background-color: transparent;
  }
}
.small.loading {
  padding-right: 35px;
}
.small.loading:after {
  width: 12px;
  height: 12px;
}
.large.loading {
  padding-right: 48px;
}
.large.loading:after {
  width: 25px;
  height: 25px;
}

@keyframes load {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}
