@import "../../styles/variables.css";

.root {
  position: relative;
  text-align: center;
  font-size: 13px;
  overflow: hidden;
  z-index: 2;
}
.root li {
  padding: 12px 0;
  display: none;
}
.root li:first-child {
  display: block;
}
.root a {
  color: inherit;
  text-decoration: underline;
  font-weight: 500;
}
.root.open li {
  display: block;
}

.more {
  position: absolute;
  top: 14px;
  right: 15px;
  color: #ffffff;
  font-size: 11px;
  display: none;
}

.danger {
  background-color: var(--red);
  border-bottom: 1px solid color(var(--red) lightness(+5%));
  color: #ffffff;
}
.warning {
  background-color: var(--orange);
  border-bottom: 1px solid color(var(--orange) lightness(+5%));
  color: #ffffff;
}

@media (min-width: 768px) {
  .more {
    display: block;
  }
  .root li {
    padding-left: 30px;
    padding-right: 30px;
  }
}
