.eyeButton {
  position: absolute;
  padding: 8px 5px;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.eyeButton:focus .passwordIcon {
  opacity: 0.6;
}

.passwordIcon {
  height: 21px;
  width: 19px;
  opacity: 0.3;
  z-index: 101;
}

.hasError {
  filter: invert(17%) sepia(10003%) saturate(7471%) hue-rotate(10deg)
    brightness(95%) contrast(186%);
}

.passwordInput {
  padding: 12px 30px 12px 15px;
}
