@import "../../../../../styles/variables.css";

.root {
  background-color: var(--gray-light);
  padding: 16px 15px;
  margin-bottom: 8px;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.root:hover,
.root:focus,
.root:active {
  background-color: var(--gray-light-darker);
}

@media (min-width: 992px) {
  .article {
    max-height: 60vh;
    overflow: auto;
  }
}
