@import "../../../styles/variables.css";

.root {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputContainer input {
  width: 84%;
}

.tagContainer {
  margin: 20px 5px;
  padding: 2px;
}

.noTagsMessage {
  font-style: italic;
  color: var(--placeholder);
}

@media (max-width: 768px) {
  .inputContainer button {
    width: 20%;
  }
  .inputContainer input {
    width: 76%;
  }
}

@media (max-width: 480px) {
  .inputContainer {
    flex-direction: column;
  }

  .inputContainer input {
    width: 100%;
  }

  .inputContainer button {
    width: 100%;
    margin-top: 10px;
  }
}
