@import "../../styles/variables.css";

.tos {
  width: auto !important;
  top: 10px !important;
  margin-bottom: 10px !important;
}
.pdf {
  max-height: 450px;
  max-height: 31vmax;
  overflow-y: auto;
}
