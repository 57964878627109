@import "../../styles/variables.css";

.toggle {
  line-height: 30px;
}

.helper {
  margin-left: 3px;
}

.helper img {
  vertical-align: top;
  height: 13px;
}
