
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes :global(slideleft) {
  0% {
    transform: translateX(100%);
  }   
  100% {
    transform: translateX(0);
  }
}
