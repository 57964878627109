table {
  border-spacing: 0;
  border-collapse: collapse;
}

th {
  text-align: left;
}

th, td {
  padding: 10px 15px;
}
