@import '../../styles/variables.css';

.root {
  border-radius: var(--border-radius-xs);
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: inline-block;
}

.root + .root {
  margin-left: 5px;
}

.root a {
  color: inherit;
}

/* COLORS */

.motive {
  background-color: var(--motive);
  color: #ffffff;
}

.green {
  background-color: var(--green);
  color: #ffffff;
}

.red {
  background-color: var(--red);
  color: #ffffff;
}

.blue {
  background-color: var(--blue);
  color: #ffffff;
}

.orange {
  background-color: var(--orange);
  color: #ffffff;
}

.dark {
  background-color: var(--motive-dark);
  color: #ffffff;
}

.default {
  background-color: var(--gray-light);
}
