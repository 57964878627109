@import "./normalize.css";
@import "./animations.css";
@import "./typography.css";
@import "./visibility.css";
@import "./utilities.css";
@import "./variables.css";
@import "./grid.css";
@import "./forms.css";
@import "./tables.css";
@import "./popup.css";

html {
  height: 100%;
}
body {
  height: 100%;
  background-color: #f3f6f8;
  scroll-behavior: smooth;
}
img {
  max-width: 100%;
  height: auto;
}
:global #app {
  min-height: 100%;
  margin-right: 0;
  transition: all 0.2s ease-in-out;
  will-change: margin-right;
}

/* Notifications */
:global .notification {
  border: none !important;
}
