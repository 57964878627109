@import "../../../styles/variables.css";

.uploadIcon {
  font-size: 54px;
  color: #dcddde;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px dashed #eeeeee;
  border-radius: 2px;
  background-color: #fafafa;
  cursor: pointer;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-bottom: 20px;
}

.dropzone:focus {
  border-color: #2196f3;
}

.disabled {
  opacity: 0.6;
  cursor: default;
  color: #bdbdbd;
  border-color: #eeeeee !important;
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;
}

.filesContainer {
  width: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 10px;
}

.thumb {
  display: inline-flex;
  margin-bottom: 8;
  margin-right: 8;
  width: 100px;
  max-height: 120px;
  padding: 4;
  align-items: flex-start;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.thumbImg {
  display: block;
  width: auto;
  height: 100%;
}

.description {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
