@import "../../../../styles/variables.css";

.root {
  display: inline-block;
  height: 62px;
  margin: 0;
  vertical-align: top;
}

.step {
  position: relative;
  display: block;
  float: left;
  text-align: center;
  padding: 8px 5px;
}
.step:nth-child(1):after,
.step:nth-child(2):before,
.step:nth-child(2):after,
.step:nth-child(3):before {
  z-index: 1;
  display: block;
  content: "";
  position: absolute;
  width: 50%;
  height: 3px;
  top: 20px;
  background-color: var(--gray);
}
.step:nth-child(1):after,
.step:nth-child(2):after {
  left: 50%;
  right: 0;
}
.step:nth-child(2):before,
.step:nth-child(3):before {
  right: 50%;
  left: 0;
}

.check, .number {
  position: relative;
  z-index: 2;
  margin: 0 auto 4px auto;
  width: 28px;
  height: 28px;
  font-size: 16px;
  line-height: 26px;
  color: var(--gray);
  outline: 2px solid #ffffff;
}
.number {
  border: 2px solid var(--gray);
  border-radius: var(--border-round);
  background-color: #ffffff;
}
.check {
  display: none;
  font-size: 28px;
  background-color: #ffffff;
  color: var(--motive);
}

.first .step:nth-child(1) .number {
  background-color: var(--motive);
  border-color: var(--motive);
  color: #ffffff;
}

.second .step:nth-child(1) .check {
  display: block;
}
.second .step:nth-child(1) .number {
  display: none;
}
.second .step:nth-child(2) .number {
  background-color: var(--motive);
  border-color: var(--motive);
  color: #ffffff;
}

.third .step:nth-child(1) .check,
.third .step:nth-child(2) .check {
  display: block;
}
.third .step:nth-child(1) .number,
.third .step:nth-child(2) .number {
  display: none;
}
.third .step:nth-child(3) .number {
  background-color: var(--motive);
  border-color: var(--motive);
  color: #ffffff;
}

.second .step:nth-child(1):after,
.third .step:nth-child(1):after,
.second .step:nth-child(2):before,
.third .step:nth-child(2):before,
.third .step:nth-child(2):after,
.third .step:nth-child(3):before {
  background-color: var(--motive);
}

@media (min-width: 992px) {
  .step {
    padding: 8px 15px;
  }
}
