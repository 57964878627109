@import "../../styles/variables.css";

.root {
  background-color: #111;
  color: #fff;
  padding: 15px 0;
  line-height: 24px;
  position: relative;
  z-index: 2;
}
.root a.link {
  color: inherit;
  display: inline-block;
  margin: 0 5px;
}

.right {
  border-top: 1px solid #333;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.copyright {
  color: var(--gray-dark);
}
.language {
  display: inline-block;
}
.copyright,
.language,
.help {
  margin-top: 20px;
}

@media (min-width: 992px) {
  .right {
    float: right;
    border: none;
    margin: 0;
  }
  .copyright,
  .language,
  .help {
    display: inline-block;
    margin-top: 0;
    margin-left: 20px;
  }
  .copyright {
    float: left;
  }
}
