@import "./variables.css";

.ReactModal__Body--open {
  overflow: hidden;
}

.CancelablePopup__Body--open {
  overflow: unset;
}

.popupOverlay,
.asideOverlay,
.cancelablePopupTransparentOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: auto;
}

.popupOverlay,
.cancelablePopupTransparentOverlay {
  z-index: 102;
  background: rgba(0, 0, 0, 0.5);
}

.cancelablePopupTransparentOverlay {
  pointer-events: none;
  background: none;
}

.asideOverlay {
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
}

.popup,
.aside {
  position: absolute;
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
}

.popup {
  z-index: 103;
  background: #fff;
}

.slideup {
  z-index: 103;
  background: #fff;
  position: absolute;
  outline: none;
  bottom: 0;
  top: 30%;
  right: 0;
  left: 0;
}

.aside {
  z-index: 101;
  background-color: var(--gray-light);
}

@media (min-width: 768px) {
  .slideup {
    z-index: 103;
    background: #fff;
    position: absolute;
    outline: none;
    bottom: 0;
    right: 100px;
    left: auto;
    top: auto;
  }

  .popup {
    top: 10%;
    left: 50%;
    right: auto;
    bottom: auto;
    width: 520px;
    max-width: 95%;
    min-height: 0;
    border-radius: var(--border-radius-lg);
    transform: translateX(-50%);
    margin-bottom: 20px;
  }

  /* ASIDE */

  .asideIsOpen {
    overflow: auto;
  }
  .asideOverlay {
    position: initial;
  }
  .aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
    width: 450px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.36);
    overflow: auto;
    animation: slideleft 0.2s ease-in-out;
  }
}

@media (max-width: 991px) {
  .cancelablePopupTransparentOverlay {
    display: none;
  }
}

@media (min-width: 1670px) {
  .asideIsOpen #app {
    margin-right: 450px;
  }
}
