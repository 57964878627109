@import '../../styles/variables.css';

.root {
  display: inline-block;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  appearance: none !important;
  transition: all 0.2s ease;
}
