@import '../../styles/variables.css';

.root {
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: var(--max-content-width);
}

.fullWidth {
  max-width: 100vw;
  padding: 0;
}
