@import "./variables.css";
@import "./typography/lato/latofonts.css";
@import "./typography/simplelineicons/simple-line-icons.css";
@import "./typography/sui/sui-font.css";

button,
input,
optgroup,
select,
textarea {
  font-family: var(--font-family-base), sans-serif;
}

html,
body {
  font-size: 14px;
  font-family: var(--font-family-base), sans-serif;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}
a,
button {
  transition: all 0.2s ease;
}
a {
  color: var(--motive);
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  color: inherit;
}

p {
  line-height: 1.5em;
  margin-top: 0;
}

b,
strong {
  font-weight: 500;
}

ul,
ol {
  padding-left: 15px;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}
dd {
  margin-left: 0;
}
dd,
dt {
  line-height: 1.7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dl-horizontal dt {
  float: left;
  width: 160px;
  clear: left;
  text-align: right;
}
.dl-horizontal dd {
  margin-left: 180px;
}

hr {
  border: none;
  border-bottom: 1px solid var(--border-color);
  margin: 20px 0;
}

del {
  position: relative;
  text-decoration: none;
}
del:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--black);
  border-radius: var(--border-radius-md);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 500;
}
.h1 {
  font-size: 2em;
  margin-bottom: 0.67em;
}
.h2 {
  font-size: 1.5em;
  margin-bottom: 0.83em;
}
.h4 {
  font-size: 1em;
  margin-bottom: 1.33em;
}

.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}

.ebay img,
.amazon img {
  vertical-align: middle;
  height: 1.8em;
}

.cdiscount img {
  vertical-align: middle;
  height: 0.9em;
}

.separator {
  border-bottom: 2px solid var(--border-color);
}

.limiter {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  margin: 28px 0;
}
.limiter span {
  color: var(--gray);
  text-transform: uppercase;
  font-size: 11px;
  left: 50%;
  padding: 0 10px;
  position: absolute;
  top: -5px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
