@font-face {
  font-family: "SUI";
  src: url("./typography/sui/SUI.eot?g6inli");
  src: url("./typography/sui/SUI.eot?g6inli#iefix") format("embedded-opentype"),
    url("./typography/sui/SUI.ttf?g6inli") format("truetype"),
    url("./typography/sui/SUI.woff?g6inli") format("woff"),
    url("./typography/sui/SUI.svg?g6inli#SUI") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="sui-"],
[class*=" sui-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "SUI" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sui-enter:before {
  content: "\e900";
}
.sui-trophy:before {
  content: "\e901";
}
.sui-shield:before {
  content: "\e902";
}
.sui-growth:before {
  content: "\e903";
}
.sui-hand:before {
  content: "\e904";
}
.sui-global-shipping:before {
  content: "\e905";
}
.sui-packages:before {
  content: "\e906";
}
.sui-laptop:before {
  content: "\e907";
}
.sui-package:before {
  content: "\e908";
}
.sui-envelope:before {
  content: "\e909";
}
.sui-parcel-s:before {
  content: "\e90a";
}
.sui-parcel-m:before {
  content: "\e90b";
}
.sui-parcel-l:before {
  content: "\e90c";
}
.sui-female:before {
  content: "\e90d";
}
.sui-male:before {
  content: "\e90e";
}
.sui-ring:before {
  content: "\e90f";
}
.sui-shirt:before {
  content: "\e910";
}
.sui-shoe:before {
  content: "\e911";
}
.sui-star:before {
  content: "\e912";
}
.sui-shield-tick:before {
  content: "\e913";
}
.sui-shield-tick-plus:before {
  content: "\e914";
}
.sui-xls-icon:before {
  content: "\e915";
}
