.root {
  display: flex;
  align-items: center;
}
.root img {
  max-height: 50vh;
}

.title {
  font-size: 5rem;
  font-weight: 600;
}

@media (min-width: 768px) {
  .root {
    height: 100vh;
  }
}
