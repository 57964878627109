.root {
  min-height: calc(100vh - 118px);
  position: relative;
}

.overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.overlayContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
